import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import BlogPostHeader from '../components/organisms/BlogPostHeader';
import DynamicContent from '../components/organisms/DynamicContent';
import ResourceRelatedArticlesSection from '../components/organisms/ResourceRelatedArticlesSection';
import ShareResourceSection from '../components/organisms/ShareResourceSection';
import WebinarHero from '../components/organisms/WebinarHero';

const EventPage = ({ data: { page } }) => {
  return (
    <Layout>
      <Seo post={page} />

      {page.event.eventType === 'Event' && (
        <BlogPostHeader
          title={page.title}
          date={page.date}
          returnUrl="/events"
          featuredImage={page?.featuredImage?.node}
          typeLabel="Event"
        />
      )}
      {page.event.eventType === 'Webinar' && (
        <WebinarHero
          title={page.title}
          date={page.date}
          eventType={page.event.eventType}
          image={page?.event?.eventHeroImage}
          content={page?.event?.eventHeroContent}
          logo={page.event.inAssociationWithLogo}
          contentDownload={page.event.contentDownload}
          formSubmitButtonText={page.event.formSubmitButtonText}
          hideWebinarTag={page.event.hideWebinarTag}
          formConfirmationHeading={page.event.eventFormConfirmation.heading}
          formConfirmationBody={page.event.eventFormConfirmation.body}
        />
      )}
      {/* <pre>{JSON.stringify(page.event, null, 4)}</pre> */}
      <DynamicContent blocks={page.event.content} />
      <ShareResourceSection backUrl="/events/" title={page.title} />
      {page.event.relatedArticles && (
        <ResourceRelatedArticlesSection
          title="Related Articles"
          viewAllUrl="/events"
          resources={page.event.relatedArticles}
        />
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query EventQuery($id: String!) {
    page: wpEvent(id: { eq: $id }) {
      ...EventSEO
      date
      title
      nodeType
      slug
      featuredImage {
        node {
          ...Image
        }
      }
      event {
        eventType
        eventDate
        eventHeroContent
        hideWebinarTag
        eventHeroImage {
          ...Image
        }
        inAssociationWithLogo {
          ...Image
        }
        formSubmitButtonText
        eventFormConfirmation {
          heading
          body
        }
        contentDownload
        content {
          ... on WpEvent_Event_Content_Wysiwyg {
            content
            fieldGroupName
          }
          ... on WpEvent_Event_Content_FullWidthImage {
            description
            fieldGroupName
            image {
              ...Image
            }
          }
          ... on WpEvent_Event_Content_SplitTextAndImage {
            content
            fieldGroupName
            imageDescription
            image {
              ...Image
            }
          }
          ... on WpEvent_Event_Content_FullWidthQuote {
            fieldGroupName
            quote
            author {
              name
              title
            }
          }
        }
      }
    }
  }
`;

export default EventPage;
