import moment from 'moment';
import React from 'react';
import { IImage } from '../../interfaces/image';
import Image from '../base/Image';
import CtaLink from '../atoms/CtaLink';
import GatedContentForm from '../molecules/GatedContentForm';
import parse from 'html-react-parser';

interface WebinarHeroProps {
  title: string;
  date: string;
  eventType: string;
  logo: IImage;
  image?: any;
  content?: string;
  contentDownload: string;
  formSubmitButtonText: string;
  formConfirmationHeading: string;
  formConfirmationBody: string;
  hideWebinarTag?: boolean;
}

const WebinarHero: React.FC<WebinarHeroProps> = ({
  title,
  date,
  image,
  content,
  eventType,
  logo,
  contentDownload,
  formSubmitButtonText,
  formConfirmationHeading,
  formConfirmationBody,
  hideWebinarTag
}) => {
  return (
    <section className="py-16 bg-tertiary lg:py-20">
      <div className="space-y-8 u-container lg:flex lg:space-x-16 lg:space-y-0">
        <div className="pt-8 lg:w-1/2">
          <div className="flex mb-12">
            <CtaLink to="/events/" label="Back to Events" reversed />
          </div>

          {hideWebinarTag !== true && (
            <div className="inline-flex items-center justify-center space-x-5 bg-white flex-2 shadow-glassdoor py-3.5 px-6 font-semibold rounded-lg mx-auto mb-9">
              <div>{eventType}</div>
              <div className="text-primary-red">
                {moment(date).format('DD MMMM')}
              </div>
            </div>
          )}

          <h1 className="u-h3" dangerouslySetInnerHTML={{ __html: title }} />
          {image && <Image className="mt-8" data={image} />}
          {content && (
            <div
              className="my-8 prose"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
          {logo && (
            <div className="mt-10 lg:mt-20">
              <div className="text-sm font-semibold mb-3.5">
                In association with
              </div>
              <img
                src={logo?.sourceUrl}
                alt={logo?.altText}
                className="w-auto h-9"
              />
            </div>
          )}
        </div>
        <div className="lg:w-1/2">
          <GatedContentForm
            url="https://go.spirable.com/l/843243/2021-02-26/mpywff"
            mergeFields={{ content_download: contentDownload }}
            submitLabel={formSubmitButtonText || 'Submit'}
            successTitle={formConfirmationHeading || 'Got it!'}
            successBody={parse(formConfirmationBody) || 'You have successfully registered for this event.'}
          />
        </div>
      </div>
    </section>
  );
};

export default WebinarHero;
